<script lang="ts" setup>
const props = defineProps<{
  isLoading: boolean
}>()
</script>

<template>
  <div v-if="props.isLoading" class="loading-dots" flex items-center justify-center>
    <span
      v-for="index in 5"
      :key="index"
      bg-primary-400
      block
      w-6px
      h-6px
      mx-2px
      rounded-full
    />
  </div>
</template>

  <style scoped>
  .loading-dots span {
    animation: loading-dots 0.8s infinite alternate;
  }

  .loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loading-dots {
    to {
      opacity: 0.3;
      transform: translateY(-6px);
    }
  }
  </style>
